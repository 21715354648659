import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import MainLogo from "../assets/image/web-GC.png";
import axios from "../utils/axios";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

// export type UserType = {
//   id: number,
//   service_id: number,
//   main_title: string,
//   sub_service_priority: number,
//   sub_title_1: string,
//   sub_title_2: string,
//   description_1: string,
//   description_2: string,
//   image_name: string,
//   created_at: "2023-04-29T18:54:06.000000Z",
//   updated_at: "2023-04-29T18:54:06.000000Z",
// };
// type UsersType = Array<UserType>;

const Header = () => {
  const [isMobile, setisMobile] = useState(false);

  const [subService, setSubservice] = useState([]);

  useEffect(() => {
    axios.get("api/get_subservices").then((res) => {
      setSubservice(res.data.data);
    });
  }, []);

  const [isSticky, setIsSticky] = useState(false);

  const handScroller = () => {
    if (window.scrollY > 230) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handScroller);

    return () => {
      window.removeEventListener("scroll", handScroller);
    };
  }, []);


  return (
    <>
      <header className={`header ${isSticky ? 'sticky' : ''}`}>

        <div className="desk version4 page-title-section-version1 one-page-top slider-active stuck-boxed-false sl-true search-true cart-false iversion-dark effect-underline subeffect-ghost fixed-false fiversion-dark wiversion-dark">
          <div className="header-content">
            <div className="header-top nz-clearfix">
              <div className="container">
                <div className="social-links header-top-social-links nz-clearfix">
                  <span className="social-text">Stay connected</span>

                  <a href="https://www.facebook.com/globalconsol/" className="fab fa-facebook-f" target="_blank"></a>
                  <a href="https://x.com/globalconsol" className="fab fa-x-twitter" target="_blank"></a>
                  <a href="https://www.linkedin.com/company/global-consolidators/"
                    className="fab fa-linkedin"
                    title="linkedin"
                    target="_blank"
                  ></a>

                  <a href="https://www.instagram.com/globalconsolidators/" target="_blank" className="fa-brands fa-instagram" ></a>
                </div>
              </div>
            </div>
            <div className="header-body">
              <div className="container">
                {/* <div className="logo logo-desk">
                <div className="hamburger-main">
                  <div></div>
                </div>
              </div> */}

                <Navbar expand="lg" className="bg-body-tertiary navbar">
                  <Navbar.Brand href="#home">
                    <Link to="/" title="Global Consol">
                      <img src={MainLogo} alt="" />
                    </Link>
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                      <Nav.Link Brand as={Link} to="/">
                        Home
                      </Nav.Link>
                      <Nav.Link Brand as={Link} to="/aboutus">
                        About us
                      </Nav.Link>
                      <NavDropdown
                        title="Services"
                        id="collasible-nav-dropdown"
                        className="navDropdown"
                      // show={show}
                      // onMouseEnter={showDropdown}
                      // onMouseLeave={hideDropdown}
                      >
                        {subService &&
                          subService.map((servicesMain) => {
                            return (
                              <NavDropdown.Item
                                className="navDropdownSub"
                                Brand
                                as={Link}
                                to={`/service/${servicesMain.main_title.replace(/\s+/g, '-').toLowerCase()}`}
                              >
                                {" "}
                                {servicesMain?.main_title}
                              </NavDropdown.Item>
                            );
                          })}
                      </NavDropdown>
                      <Nav.Link
                        Brand
                        as={Link}
                        className="nav-link"
                        to="/where-we-are"
                      >
                        Where we are
                      </Nav.Link>
                      <Nav.Link
                        Brand
                        as={Link}
                        className="nav-link"
                        to="/career"
                      >
                        Career
                      </Nav.Link>
                      <Nav.Link
                        Brand
                        as={Link}
                        className="nav-link"
                        to="/contact-us"
                      >
                        Contact us
                      </Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
